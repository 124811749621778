<template>
	<div class="content-wrap genetic-project-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox
			:defaultOpen="true"
			@onSearch="searchHandle"
			@onReset="reset"
		>
			<CtmsSearchBoxItem :span="1" label="项目名称">
				<Input
					clearable
					placeholder="请输入项目名称"
					v-model="searchParams.projectName"
				/>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1.5" label="承担科室">
				<Select
					v-model="searchParams.departments"
					placeholder="请选择承担科室"
					class="depart-select"
					:max-tag-count="1"
					:transfer="true"
					filterable
					multiple
				>
					<Option
						v-for="item in departmentList"
						:value="item.name"
						:key="item.id"
						>{{ item.name }}</Option
					>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1.5" label="主要研究者(PI)">
				<Select
					v-model="searchParams.trialSitePiNames"
					placeholder="请选择主要研究者(PI)"
					class="depart-select"
					:max-tag-count="1"
					:transfer="true"
					filterable
					multiple
				>
					<Option
						v-for="item in userList"
						:value="item.realName"
						:key="item.id"
						>{{ item.realName }}</Option
					>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		>
		</CtmsDataGrid>
	</div>
</template>

<script>
import { mapState } from "vuex"
import proApi from "@/api/project/project"
import contractApi from "@/api/project/contract"
import publicApi from "@/api/public"

const { apiGetPage } = proApi
const { publicGetuserList } = publicApi
const { apiGetDeparts } = contractApi

export default {
	name: "genetic",
	data() {
		return {
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			listData: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 300
				},
				{
					title: "研究者",
					minWidth: 200,
					key: "trialSiteUsersName"
				},
				{
					title: "最新文件上传日期",
					minWidth: 200,
					key: "lastedUpdateTime",
					render: (h, params) =>
						h(
							"span",
							params.row.lastedUpdateTime
								? params.row.lastedUpdateTime.slice(0, -2)
								: "--"
						)
				},
				{
					title: "操作",
					key: "action",
					width: 200,
					fixed: "right",
					renderButton: params => {
						const btnList = [
							{
								label: "进入遗传办文件列表",
								on: {
									click: () => {
										this.$router.push({
											name: "ResourceGeneticList",
											query: {
												projectName: params.row.projectName
											},
											params: {
												projectId: params.row.projectId
											}
										})
									}
								}
							}
						]
						return btnList.filter(item => !!item)
					}
				}
			],
			loading: false,
			searchParams: {
				projectName: "",
				trialSitePiNames: [],
				departments: []
			},
			oldSearchParams: {},
			departmentList: [],
			userList: []
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 100
			}
		})
	},
	created() {
		this.getPublicData()
		this.getProjectList()
	},
	methods: {
		async getProjectList() {
			this.loading = true
			const piNames =
				this.oldSearchParams.trialSitePiNames &&
				this.oldSearchParams.trialSitePiNames.length
					? this.oldSearchParams.trialSitePiNames.join(",")
					: ""
			const departNames =
				this.oldSearchParams.departments &&
				this.oldSearchParams.departments.length
					? this.oldSearchParams.departments.join(",")
					: ""

			const res = await apiGetPage({
				projectName: this.oldSearchParams.projectName || "",
				trialSitePiNames: piNames,
				departments: departNames,
				isOpenHgr: 1,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res) {
				this.listData = res.data.list || []
				this.page.total = res.data.total
			}
			this.loading = false
		},
		getPublicData() {
			this.$asyncDo(async () => {
				const res = await publicGetuserList({
					isDistinc: 1 // 去除重名信息
				})
				if (res) {
					this.userList = res.data || []
				}
				const res2 = await apiGetDeparts()
				if (res2) {
					this.departmentList = res2.data || []
				}
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.getProjectList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.getProjectList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				projectName: "",
				trialSitePiNames: [],
				departments: []
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.docfileId = ""
			this.page.current = 1
			this.$nextTick(() => {
				this.getProjectList()
			})
		}
	}
}
</script>
<style lang="less">
.genetic-project-wrap {
	.depart-select.ivu-select-multiple {
		min-height: 32px;
	}
	.depart-select.ivu-select-multiple .ivu-tag {
		max-width: 90px;
	}
}
</style>
