import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/project"

export default {
	...commonApi(moduleName),
	// 获取文件列表
	apiGetPage: data =>
		request(`/project/${data.projectId}/contract/docfiles`, {
			method: "get",
			params: data
		}),
	// 新增文件名称
	addFile: data =>
		request(`/project/${data.projectId}/contract/docfiles/mkdir`, {
			method: "post",
			body: data
		}),
	// 文件上传
	uploadFile: data =>
		request(`/project/${data.projectId}/contract/docfiles/mkfile`, {
			method: "post",
			body: data.body
		}),
	// 删除文件
	deleteFile: data =>
		request(`/project/${data.projectId}/contract/docfile/${data.docfileId}`, {
			method: "delete"
		}),
	// 替换文件
	replaceFile: data =>
		request(
			`/project/${data.projectId}/contract/docfile/${data.docfileId}/replace`,
			{
				method: "post",
				body: data.body
			}
		),
	// 获取文件目录树
	getTreeList: data =>
		request(`/project/${data.projectId}/docfiles/tree`, { method: "get" }),
	// 获取所有医院的科室
	apiGetDeparts: () => request("/auth/hospital-departments", { method: "get" }),
	// 获取合同管理列表
	apiGetContractList: projectId =>
		request(`/project/${projectId}/contract/list`, { method: "get" }),
	// 获取新增合同的项目基本信息
	apiGetProDetail: projectId =>
		request(`/project/${projectId}/contract/project_basic_data`, {
			method: "get"
		}),
	// 查看合同详情
	apiGetContractDetail: data =>
		request(`/project/${data.projectId}/contract/${data.contractId}`, {
			method: "get"
		}),
	// 合同報表 查看合同详情
	apiGetReportContractDetail: data =>
		request("/report/contract/detail", {
			method: "get",
			params: data
		}),
	// 新增合同
	addContract: data =>
		request(`/project/${data.projectId}/contract`, {
			method: "post",
			body: data
		}),
	// 修改合同
	editContract: data =>
		request(`/project/${data.projectId}/contract`, {
			method: "put",
			body: data
		}),
	// 删除合同
	deleteContract: data =>
		request(`/project/${data.projectId}/contract/${data.contractId}`, {
			method: "delete"
		}),
	// 上传合同资料
	uploadContractFile: (projectId, data) =>
		request(`/project/${projectId}/contract/upload`, {
			method: "post",
			body: data
		})
}
